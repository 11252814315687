<template>
  <div class="main">
    <searchBar class="searchbar_style" :search-out="searchData" />
    <treeSelect class="treeSelect" :options="pddOptions">
      <pddListRow
        v-if="pddOptions.list && pddOptions.goodsParams"
        :pdd-list-row-data="pddOptions.list"
        :goods-params="pddOptions.goodsParams"
        @sendPage="pddOptions.goodsParams.changePage"
      />
    </treeSelect>
  </div>
</template>
<script>
/* eslint-disable eqeqeq */
// tree-select
import Vue from 'vue'
import {
  Search,
  Toast,
  TreeSelect
} from 'vant'
import pddListRow from '@/component/Goods/pddListRow'
import treeSelect from '@/component/treeSelect'
import searchBar from '@/component/head/search'
import { pddAuth } from '@/utils/pdd'
import { fetchPddHome, fetchPddHomeGoodsList } from '@/services/shop-cps/shop-pdd-cps'

Vue
  .use(Toast)
  .use(Search)
  .use(TreeSelect)
export default {
  name: 'PddList',
  components: {
    pddListRow,
    treeSelect,
    searchBar
  },
  data() {
    return {
      searchData: {
        isShowSearch: true, // 输入框 title 控制
        bgColor: '#fff', // 背景颜色
        isShowReturn: true, // 返回 上一页 显隐
        callbackGo: this.callbackGo, // 点击返回 上一页 回调
        isSearch: '', // input val
        placeholder: '拼得多，省的多', // placeholder
        callbackDown: this.callbackInputGo, // 搜索 路由页面
        callbackBtnGo: this.callbackBtnGo
      },
      pddOptions: {
        active: 0,
        items: [],
        clickNav: this.changePddTab,
        list: [],
        goodsParams: {
          id: '',
          page: 1,
          pageSize: 10,
          loading: false,
          finished: false,
          error: false,
          changePage: this.changePage
        }
      },

      active: 0,
      pddBannerList: {},
      goodsDetail: [],
      // goodsParams: {
      //   id: "",
      //   page: 1,
      //   pageSize: 10,
      //   loading: false,
      //   finished: false,
      //   error: false,
      // },
      searchValue: '' // 搜索关键字
    }
  },

  beforeMount() {},
  mounted() {
    this.getPddInfo()
  },
  methods: {
    jumpOutPdd() {
      // location.href = this.pddBannerList.bannerData[0].link;
    },
    // 返回上一页
    callbackGo: function() {
      this.$router.go(-1)
    },
    // 搜索
    callbackBtnGo() {
      this.$router.push('./goodsSearch')
    },
    callbackInputGo: function(val) {
      if (val.trim()) {
        // 存储搜索历史_丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅___________
        const newKeyList = JSON.parse(
          window.localStorage.getItem('searchHistoryKey')
        )
        this.searchHistoryList = newKeyList.length >= 1 ? newKeyList : []
        if (this.searchHistoryList.indexOf(val) < 0) {
          this.searchHistoryList.unshift(val)
          const localObj = JSON.stringify(this.searchHistoryList)
          window.localStorage.setItem('searchHistoryKey', localObj)
        }
        // 存储搜索历史_丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄___________
        // this.$router.push({
        //   path: "/goodsList",
        //   query: {
        //     searchKeyword: val,
        //     // pddList
        //     fromPage: "goodsSearch",
        //   },
        // });
      }
    },
    changePage(e) {
      this.pddOptions.goodsParams.page = e.nowPage
      this.getPddDetailList(this.pddOptions.goodsParams.id)
    },

    // 获取banner信息
    async getPddInfo() {
      await pddAuth()
      fetchPddHome().then((res) => {
        if (res.code == 200) {
          if (res.data.classData && res.data.classData.length !== 0) {
            res.data.classData.forEach((e) => {
              e.text = e.opt_name
            })
            this.pddOptions.items = res.data.classData
          }
          this.pddBannerList = res.data
          this.pddOptions.goodsParams.id = res.data.classData[0].opt_id
          this.getPddDetailList(this.pddOptions.goodsParams.id)
        }
      })
    },
    // 获取商品列表
    getPddDetailList(sendId) {
      this.pddOptions.goodsParams.id = sendId
      const newParams = {
        mid: localStorage.getItem('uid'),
        id: this.pddOptions.goodsParams.id,
        page: this.pddOptions.goodsParams.page,
        pageSize: this.pddOptions.goodsParams.pageSize
      }
      fetchPddHomeGoodsList(newParams)
        .then((res) => {
          if (res.code == 200) {
            if (res.data.data === null) {
              // 没数据则加载完毕
              this.pddOptions.goodsParams.finished = true
              return
            }
            if (res.data.length < 1 || !res.data) {
              this.pddOptions.goodsParams.finished = true
              return
            } else {
              this.pddOptions.list = this.pddOptions.list.concat(res.data)
              //   this.goodsDetail = this.goodsDetail.concat(res.data);
            }
            this.pddOptions.goodsParams.loading = false
          }
        })
        .catch((error) => {
          console.log(error)
          this.pddOptions.goodsParams.error = true
        })
        .finally(() => {
          this.pddOptions.goodsParams.loading = false
        })
    },

    // tab切换商品list
    changePddTab(index) {
      this.pddOptions.list = []
      this.pddOptions.goodsParams.id = this.pddOptions.items[index].opt_id
      this.pddOptions.goodsParams.page = 1
      this.getPddDetailList(this.pddOptions.goodsParams.id)
    },

    // 拼多多首页搜索
    pddSearch(val) {
      // if (val.trim()) {
      //   //存储搜索历史_丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅___________
      //   const newKeyList = JSON.parse(
      //     window.localStorage.getItem("searchHistoryKey")
      //   );
      //   this.searchHistoryList = newKeyList.length >= 1 ? newKeyList : [];
      //   if (this.searchHistoryList.indexOf(val) < 0) {
      //     this.searchHistoryList.unshift(val);
      //     let localObj = JSON.stringify(this.searchHistoryList);
      //     window.localStorage.setItem("searchHistoryKey", localObj);
      //   }
      //   //存储搜索历史_丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄___________
      //   this.$router.push({
      //     path: "/goodsList",
      //     query: {
      //       searchKeyword: val,
      //       fromPage: "pddList",
      //     },
      //   });
      // }
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  background: #f6f6f6;

}

.searchmain {
  background: #e03022;
  padding: 10px 14px;
}

.searchdiv {
  width: 350px;
  height: 34px;
  border-radius: 17px;
  /*background: #F0F2F5;*/

  .van-search {
    background-color: #e03022;
    padding: 0;
    /*padding-bottom: 0;*/
  }
}

.van-icon__image {
  float: left;
  width: 20px;
  height: 20px;
  margin-top: 7px;
  margin-left: 5px;
}

.fangdajing {
  float: left;
  width: 20px;
  height: 20px;
  margin-top: 7px;
  margin-left: 5px;
}

.searchinput {
  float: left;
  font-size: 13px;
  color: #999999;
  height: 34px;
  line-height: 34px;
  width: 300px;
  background: #f0f2f5;
}

.circlediv {
  width: 100%;
  overflow: hidden;
  text-align: center;
  background: #f5f6fd;
  height: 174px;
  position: relative;
  /*background-color: darkcyan;*/
}

.circle {
  width: 140%;
  height: 50px;
  background: #e03022;
  border-radius: 0 0 50% 50%;
  border-top: none;
  position: relative;
  left: -20%;
}

.headimgdiv {
  position: absolute;
  width: 346px;
  height: 150px;
  border-radius: 8px;
  left: 50%;
  top: 50%;
  /*bottom: 17px;*/
  transform: translate(-50%, -50%);
  /*left: 14px;*/
  /*top: 10px;*/
  /*background: blue;*/
}

.my-swipe {
  border-radius: 8px;
  overflow: hidden;
}

.my-swipe .van-swipe-item {
  border-radius: 8px;
  overflow: hidden;

  img {
    border-radius: 8px;
  }
}

/*.headimg {*/
/*    width: 346px;*/
/*    height: 150px;*/
/*    border-radius: 8px;*/
/*}*/

/deep/ .van-tabs__nav {
  background: #f5f6fd;
}

/deep/ .van-tab {
  position: relative;
}

/deep/ .van-tab::after {
  position: absolute;
  content: "";
  height: 45%;
  width: 2px;
  background: #d2d7e0;
  right: 0;
  top: 27%;
}

/deep/ .van-sidebar-item--select::before{
  background: rgba(0,0,0,0,0);
}

.goodsList {
  // margin-top: 10px;
  // width: 375px;
}
.treeSelect{
     //margin-top:-2px;
    //margin-top:40px;
}
.searchbar_style{
  height: 40px;
}
</style>
