/**
 * 拼多多工具函数
 */
import { fetchPddAuth, fetchPddPromUrlGenerate } from '@/services/shop-cps/shop-pdd-cps'

/**
 * 拼多多授权设置
 * 2021-05-17 15:44:26
 * @author SnowRock
 * @returns {Promise<void>}
 */
export async function pddAuth() {
  const uid = window.localStorage.getItem('uid')
  const res = await fetchPddAuth({ uid })
  const { code = null } = res

  if (res && code === 200 && res.data && res.data.bind !== 1) {
    const { code = null, data = null } = await fetchPddPromUrlGenerate({
      uid, channel_type: 10
    })
    if (code === 200) {
      const { url } = data
      // H5授权
      if (url) {
        window.location.href = url
      }
    }
  }
}
