<template>
  <div>
    <van-list
      v-model="goodsParams.loading"
      :finished="goodsParams.finished"
      finished-text="已加载完毕"
      offset="300"
      :error.sync="goodsParams.error"
      error-text="请求失败，点击重新加载"
      :immediate-check="false"
      @load="onLoad"
    >
      <!--van-cell 第一、二项不渲染 无 data-v-xxxxx 故改为div-->
      <!-- {{pddListRowData}} -->

      <ul class="treeSelectBox">
        <li
          v-for="(item, index) in pddListRowData"
          :key="index"
          class="treeSelectLi"
        >
          <div
            class="goodsbox"
            @click.prevent="jumpPddProduct(item.info.goods_id)"
          >
            <div class="goodsleft">
              <imgLoading :src="item.info.goods_image_url" class="goodsimg" />
            </div>
            <div class="goodsright">
              <div>
                <div class="goods_name">
                  <!-- <div class="goodsIcon">拼多多</div> -->
                  {{ item.info.goods_name }}
                </div>

                <div class="goodsyhjdiv">
                  <div
                    v-for="(content, num) in (item.coupon.slice(0, 1).filter(i => i.coupon_discount && i.coupon_discount>0))"
                    :key="num"
                    class="goodsyhj"
                  >
                    {{ content.coupon_discount }}元券
                  </div>
                  <div class="goodspl">分享赚¥ {{ item.growthValue.share }}</div>
                </div>
                <!--                <div  class="goodNum"> 已售{{ item.info.sales_tip }}件</div>-->
              </div>
              <div class="goodsright_bottom">
                <div class="goodspricediv">
                  <div class="goodspicediv" style="overflow: hidden">
                    <div style="float: left">
                      <span class="goodspricenow">¥{{ item.info.jh_price }}</span>
                      <span class="goodspriceold">¥{{ item.info.min_normal_price }}</span>
                    </div>

                    <div class="sales_tip">已售{{ item.info.sales_tip }}件</div>
                  </div>
                </div>
              </div>
              <!-- <div class="buyIt">抢</div> -->
            </div>
          </div>
        </li>
      </ul>

      <!-- <div class='boxCell' v-for="(item,index) in pddListRowData" :key="index">
                <div class="goodsbox" @click.prevent="jumpPddProduct(item.info.goods_id)">
                    <div class="goodsleft">
                        <img :src="item.info.goods_image_url" class="goodsimg">
                    </div>
                    <div class="goodsright">
                        <div>
                            <div class="goods_name">
                                <div class="goodsIcon">拼多多</div>
                                {{item.info.goods_name}}
                            </div>
                            <div class="goodsyhjdiv">

                                <div class="goodsyhj" v-for="(content,num) in item.coupon.slice(0,1)" :key="num">
                                    {{content.coupon_discount}}元优惠券
                                </div>
                                <div class="goodspl">{{item.info.sales_tip}}人购买</div>
                            </div>
                        </div>
                        <div class="goodsright_bottom">
                            <div class="goodspricediv">
                                <div class="goodspicediv">
                                    <div class="goodspricenow">¥{{item.info.jh_price}}</div>
                                    <div class="goodspriceold">¥{{item.info.min_normal_price}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="buyIt">抢</div>

                    </div>
                </div>
            </div> -->
    </van-list>
  </div>
</template>
<script>
import imgLoading from '@/component/imgLoading'

export default {
  name: 'PddListRow',
  components: {
    imgLoading
  },
  props: {
    pddListRowData: {
      type: null,
      default: null
    },
    'goodsParams': {
      type: null,
      default: null
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    // jump_buy(url) {
    // location.href = url;
    // },

    onLoad() {
      this.$nextTick(() => {
        this.$emit('sendPage', { nowPage: this.goodsParams.page + 1 })
      })
    },

    // 跳转拼多多商品详情
    jumpPddProduct(goodsId) {
      // /goodsDetailCPS?goodsId='商品ID'&status=（3:京东；4:拼多多；5:淘宝；6:唯品会）
      this.$router.push({
        path: '/goodsDetailCPS',
        query: {
          goodsId: goodsId,
          status: 4
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.boxCell {
  margin-bottom: 8px;
  border-radius: 8px;
  background: #ffffff;
  padding: 12px 14px;
}

.boxCell::after {
  border-bottom: none;
}

.goodsIcon {
  display: inline-block;
  /*width: 20px;*/
  height: 20px;
  line-height: 20px;
  padding: 0 2px;
  margin-right: 3px;
  border-radius: 1px;
  background: rgba(221, 39, 39, 1);
  color: #fff;
  font-weight: bold;
}

.goodsright_bottom {
  width: 100%;
}

.goodspricediv {
  /*margin-top: 5px;*/
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.goodspicediv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .sales_tip {
    font-size: 10px;
    transform: scale(.7, .7);
    color: #666;
  }
}

.goodspricenow {
  color: #ed2e2e;
  font-weight: bold;
  font-size: 14px;
}

.goodspriceold {
  font-size: 12px;
  color: #999999;
  text-decoration: line-through;
  margin-left: 2px;
}

.buyIt {
  width: 40px;
  height: 40px;
  background-color: #ed2e2e;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 18px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.treeSelectBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #f7f8fa;
  // align-items: center;
  padding: 10px 2px;

  //   width: 100%;
  .treeSelectLi {
    text-align: left;
    background: #fff;
    margin-bottom: 12px;
    border-radius: 5px;
    width: 48.5%;
    overflow: hidden;

    .goodsbox {
      background: #ffffff;
      position: relative;

      .goodsleft {
        width: 100%;
        margin-bottom: 10px;
        // height: 140px;

        img {
          width: 100%;
        }

        /*border:1px solid red;*/
      }

      .goodsright {
        padding: 5px;

        .goods_name {
          color: #141414;
          font-size: 12px;
          margin-top: 4px;
          height: 38px;
          line-height: 20px;
          /*font-weight: bold;*/
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .goodsyhjdiv {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin: 2px 0;

          .goodsyhj {
            background: url("../../assets/images/yhj_red.png") no-repeat;
            background-size: 100%;
            width: 36%;
            height: 16px;
            text-align: center;
            line-height: 16px;
            font-size: 10px;
            color: #fff;
            vertical-align: middle;
          }

          .goodspl {
            font-size: 10px;
            color: #999999;
            margin-left: 2px;
            color: #ff001b;
            background: #ffe9e9;
            padding: 3px 1px;
            border-radius: 5px;
            height: 18px;
            vertical-align: middle;
          }

        }

        .goodNum {
          font-size: 10px;
          margin: 5px 0 5px 0;
          color: #ccc;
        }
      }
    }
  }
}
</style>
