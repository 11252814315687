/**
 * 拼多多CPS相关
 */
import request from '@/services/request'
import { targetUrl } from '@/services/shop-cps/shop-jd-cps'

/**
 * 获取拼多多首页信息
 * 2021-06-02 15:18:40
 * @author SnowRock
 * @param params 入参
 * @returns {Promise<*|*|undefined>}
 */
export function fetchPddHome(params) {
  return request({
    url: targetUrl('/cpsreforge/pdd/index'),
    method: 'post',
    data: params
  })
}

/**
 * 拼多多首页商品列表
 * 2021-06-02 15:18:40
 * @author SnowRock
 * @param params 入参
 * @param params.mid 用户id
 * @param params.id  猜你喜欢 987654321
 * @param params.page 页码
 * @param params.pageSize 条数
 * @returns {Promise<*|*|undefined>}
 */
export function fetchPddHomeGoodsList(params) {
  return request({
    url: targetUrl('/cpsreforge/pdd/getHomeGoodsList'),
    method: 'post',
    data: params
  })
}

/**
 * 拼多多商品详情
 * 2021-06-02 15:18:40
 * @author SnowRock
 * @param params 入参
 * @param params.uid 用户id
 * @param params.goods_id 商品id
 * @returns {Promise<*|*|undefined>}
 */
export function fetchPddGoodsDetails(params) {
  return request({
    url: targetUrl('/cpsreforge/pdd/getGoodsInfo'),
    method: 'post',
    data: params
  })
}

/**
 * 获取拼多多是否授权
 * 2021-06-02 15:18:40
 * @author SnowRock
 * @param params 入参
 * @param params.uid 用户id
 * @returns {Promise<*|*|undefined>}
 */
export function fetchPddAuth(params) {
  return request({
    url: targetUrl('/cpsreforge/pdd/pddMemberAuthorityQuery'),
    method: 'post',
    data: params
  })
}
/**
 * 获取拼多多营销工具推广链接
 * 2021-06-02 15:18:40
 * @author SnowRock
 * @param params 入参
 * @param params.uid 用户id
 * @param params.channel_type 10 固定为10
 * @returns {Promise<*|*|undefined>}
 */
export function fetchPddPromUrlGenerate(params) {
  return request({
    url: targetUrl('/cpsreforge/pdd/pddRpPromUrlGenerate'),
    method: 'post',
    data: params
  })
}

/**
 * 拼多多关键词搜索
 * 2021-06-02 15:18:40
 * @author SnowRock
 * @param params 入参
 * @param params.keyword 关键词
 * @param params.mid 用户id
 * @param params.page 页码
 * @param params.page_size 条数
 * @param params.sort_type 排序方式:0-综合排序;1-按佣金比率升序;2-按佣金比例降序;3-按价格升序;4-按价格降序;5-按销量升序;6-按销量降序;7-优惠券金额排序升序;8-优惠券金额排序降序;9-券后价升序排序;10-券后价降序排序;11-按照加入多多进宝时间升序;12-按照加入多多进宝时间降序;13-按佣金金额升序排序;14-按佣金金额降序排序;15-店铺描述评分升序;16-店铺描述评分降序;17-店铺物流评分升序;18-店铺物流评分降序;19-店铺服务评分升序;20-店铺服务评分降序;27-描述评分击败同类店铺百分比升序，28-描述评分击败同类店铺百分比降序，29-物流评分击败同类店铺百分比升序，30-物流评分击败同类店铺百分比降序，31-服务评分击败同类店铺百分比升序，32-服务评分击败同类店铺百分比降序
 * @returns {Promise<*|*|undefined>}
 */
export function fetchPddSearchGoods(params) {
  return request({
    url: targetUrl('/cpsreforge/pdd/searchGoods'),
    method: 'post',
    data: params
  })
}
